import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  onError(event) {
    let [data, status, xhr] = event.detail;
    console.log(data);
  }
}
