import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["authTokenField", "revealButton"]

  toggleAuthToken() {
    if (this.authTokenFieldTarget.type == 'password') {
      this.authTokenFieldTarget.type = 'text';
    } else {
      this.authTokenFieldTarget.type = 'password';
    }

    if (this.revealButtonTarget.innerHTML == 'Reveal') {
      this.revealButtonTarget.innerHTML = 'Hide'
    } else {
      this.revealButtonTarget.innerHTML = 'Reveal';
    }
  }
}
