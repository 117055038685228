import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "preview",
    "field",
    "imageField",
    "imagePreview",
    "displayName"
  ]

  connect() {
    this.submit();
  }

  submit() {
    let xhttp = new XMLHttpRequest();
    let data = new FormData(this.formTarget);
    let controller = this;
    let preview = this.previewTarget;
    let imageField = this.imageFieldTarget;
    let hasImagePreview = this.hasImagePreviewTarget;
    let parameters = []
    if (controller.data.get("id")) {
      parameters.push(`id=${controller.data.get("id")}`)
    }
    for (let pair of data.entries()) {
      parameters.push(
        encodeURIComponent(pair[0]) + '=' +
        encodeURIComponent(pair[1])
      );
    }
    xhttp.open("GET", "/charities/preview?" + parameters.join('&'), true);
    xhttp.send();
    xhttp.onload = function () {
    if (xhttp.readyState === xhttp.DONE) {
        if (xhttp.status === 200) {
          let response = JSON.parse(xhttp.response);
          preview.innerHTML = response.data;
          // Update Preview image if needed
          if (hasImagePreview && imageField.files[0]) {
            controller.imagePreviewTarget.src = URL.createObjectURL(imageField.files[0])
            controller.displayNameTarget.remove()
          }
        }
      }
    };
  }

  update() {
    this.fieldTarget.classList.toggle('is-hidden');
  }
}
