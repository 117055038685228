import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "preview", "field"]

  connect() {
    this.submit();
  }

  submit() {
    var xhttp = new XMLHttpRequest();
    var data = new FormData(this.formTarget);
    var preview = this.previewTarget;
    var parameters = []
    for (var pair of data.entries()) {
        parameters.push(
            encodeURIComponent(pair[0]) + '=' +
            encodeURIComponent(pair[1])
        );
    }
    xhttp.open("GET", "/notices/preview?" + parameters.join('&'), true);
    xhttp.send();
    xhttp.onload = function () {
    if (xhttp.readyState === xhttp.DONE) {
        if (xhttp.status === 200) {
          var response = JSON.parse(xhttp.response);
          preview.innerHTML = response.data;
        }
      }
    };
  }

  update() {
    this.fieldTarget.classList.toggle('is-hidden');
  }
}
