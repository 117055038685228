import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["client", "length"]

  connect() {
    this.setQuantity()
  }

  setQuantity() {
    const selectedIndex = this.clientTarget.selectedIndex
    const length = this.clientTarget.options[selectedIndex].dataset.length
    this.lengthTarget.value = length
  }
}
