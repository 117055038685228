import { createConsumer } from "@rails/actioncable"
import { Controller } from "@hotwired/stimulus"
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

export default class extends Controller {
  static targets = ["bell", "notifications"]

  initialize() {
    library.add(far)
    library.add(fas)
  }

  connect() {
    // Initialize bell icon
    dom.i2svg()

    // Establish websocket
    var cable = createConsumer()
    cable.subscriptions.create(
      {
        channel: "NotificationsChannel",
        id: this.data.get("id")
      },
      {
        received: data => {
          this.fillInBell()
          var a = document.createElement('a')
          a.classList.add('navbar-item', 'has-text-grey-dark', 'bell-notification')
          a.href = `/codes/${data.code}`
          a.innerHTML = data.message
          this.notificationsTarget.appendChild(a)
        }
      }
    )
  }

  fillInBell() {
    if (!this.bellTarget.classList.contains('has-text-grey-dark')) {
      return true
    }

    var parentElement = this.bellTarget.parentElement
    parentElement.removeChild(this.bellTarget)

    var div = document.createElement('div')
    div.classList.add('bell', 'navbar-item', 'has-dropdown', 'is-hoverable')

    var list = document.createElement('div')
    list.classList.add('navbar-dropdown', 'is-right')
    list.setAttribute('data-notifications-target', 'notifications')

    var link = document.createElement('a')
    link.href = '/notifications'
    link.classList.add('navbar-link')
    link.setAttribute('data-notifications-target', 'bell')

    var i = document.createElement('i')
    i.classList.add('fas', 'fa-bell')

    link.appendChild(i)
    div.appendChild(link)
    div.appendChild(list)
    parentElement.appendChild(div)
    dom.i2svg()
  }
}
