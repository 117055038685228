// Entry point for the build script in your package.json
import "./controllers"
import "@hotwired/turbo-rails"
import "@rails/request.js"

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

import { RequestInterceptor } from '@rails/request.js'
// ...

// Set interceptor
RequestInterceptor.register(async (request) => {
  const token = await getSessionToken(window.app)
  request.addHeader('Authorization', `Bearer ${token}`)
})

// Reset interceptor
RequestInterceptor.reset()

import { FetchRequest } from "@rails/request.js"
import { navigator } from "@hotwired/turbo"

function showProgressBar() {
  navigator.delegate.adapter.progressBar.setValue(0)
  navigator.delegate.adapter.progressBar.show()
}

function hideProgressBar() {
  navigator.delegate.adapter.progressBar.setValue(1)
  navigator.delegate.adapter.progressBar.hide()
}

export function withProgress(request) {
  showProgressBar()

  return request.then((response) => {
    hideProgressBar()
    return response
  })
}

export function get(url, options) {
  const request = new FetchRequest("get", url, options)
  return withProgress(request.perform())
}
