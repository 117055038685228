import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = ["button", "input", "message", "modal"]
  static values = {
    url: String
  }

  onSubmit(event) {
    event.preventDefault()

    const request = new FetchRequest("post", this.urlValue, {
      body: new FormData(event.target)
    })
    request.perform().then(response => {
      response.json.then((json) => {
        this.messageTarget.innerHTML = json.data
        this.modalTarget.classList.add('is-active')
        this.inputTarget.value = ''
      })
    })
  }

  reset() {
    if (this.buttonTarget.disabled) {
      if (window.grecaptcha) grecaptcha.reset();
    }
    this.messageTarget.innerHTML = '';
    this.modalTarget.classList.remove('is-active');
  }

  closeModal() {
    this.reset();
    this.modalTarget.classList.remove('is-active');
  }
}
